import React, { Component } from "react";

const ServiceList = [
  {
    icon: "01",
    title: "Order Anything",
    description:
      "Choose from thousands of products from shops and stores near Vijaywada and Guntur.",
  },
  {
    icon: "02",
    title: "Fast Deliveries",
    description:
      "Orders deliveried to your Hostels within minutes, no more waiting for days.",
  },
  {
    icon: "03",
    title: "Low Cost Everything",
    description:
      "Delivery charges less than 50₹, find site and shop offers exclusively for students.",
  },
];

class ServiceOne extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          {ServiceList.map((val, i) => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
              <div className="service service__style--1">
                <div className="icon">
                  <img
                    src={`/assets/images/icons/icon-${val.icon}.png`}
                    alt="Digital Agency"
                  />
                </div>
                <div className="content">
                  <h4 className="title">{val.title}</h4>
                  <p>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceOne;
