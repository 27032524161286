import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import { FiClock, FiUser, FiMessageCircle, FiHeart } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class Hiring extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Acquiring Talent!" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h2 className="title theme-gradient">
                    Building <br /> The Dream Team!
                  </h2>
                  <ul className="blog-meta d-flex justify-content-center align-items-center">
                    <li>
                      <FiClock />
                      May 4, 2023
                    </li>
                    <li>
                      <FiUser />
                      Jones David
                    </li>
                    {/* <li>
                      <FiMessageCircle />
                      15 Comments
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    <p>
                      Locaro is a hyperlocal product delivery platform with a
                      massive network of shops and stores throughout India that
                      allows you to compare prices, find the best deals, and get
                      10-minute deliveries for any product you want, anytime,
                      anywhere. Our B2B services empower small to medium
                      businesses by helping them create a brand identity and get
                      started selling online locally within minutes. We provide
                      delivery partners, a free and simple inventory management
                      solution, and low-cost promotion to put your businesses on
                      the map.{" "}
                    </p>
                    {/* <p className="mt--40">
                      We're looking to start this venture on campus with a
                      spin-off version named 'Locaro x VITAP' that will allow
                      you to order 1000s of products from shops and stores in
                      Vijaywada-Guntur as well as get food deliveries from your
                      favorite restaurants.{" "}
                    </p> */}

                    <p>
                      We're mostly looking for people who have already shown
                      excellence in these fields with a trackable set of
                      projects and achievements. Since we're a startup, anyone
                      that gets selected will work as a core member of Locaro
                      and their role in the company will require them to do
                      things outside of their skillset, and as such require them
                      to learn and adapt as need be.
                    </p>

                    <div className="blog-single-list-wrapper d-flex flex-wrap">
                      <div className="thumbnail">
                        <img
                          className="w-100"
                          src="/assets/images/about/about-1.png"
                          alt="BLog Images"
                        />
                        {/* <span>
                            We're looking for the next generation of 10x
                            developers with the entrepreneurial spirit to
                            revolutionize, innovate, and challenge the status-quo.
                            Will you join us?
                          </span> */}
                      </div>
                      <div className="content">
                        <p>
                          We're building a team of 10x developers with the
                          entrepreneurial spirit to revolutionize, innovate, and
                          challenge the status quo. Will you join us?
                        </p>

                        <p>
                          <i>
                            Joining the company counts towards your work
                            experience and an internship certificate will be
                            provided.
                          </i>
                        </p>
                        <h4 className="title">Currently Looking for: </h4>
                        <ul className="list-style">
                          <li>Front-end Developer (React)</li>
                          <li>Front-end Developer (Flutter or React Native)</li>
                          <li>
                            Back-end Developer (NodeJS, Express, PostgreSQL)
                          </li>
                        </ul>
                        <p className="mt--40">
                          <h5 className="title">
                            Key Attributes of members on the team:
                          </h5>
                          <ul className="list-style">
                            <li>Problem solving ability</li>
                            <li>Work well with a distributed technical team</li>
                            <li>
                              Ability to quickly learn new technologies and
                              languages
                            </li>
                            <li>
                              Ability to work with stakeholders in developing
                              tooling to meet requirement
                            </li>
                          </ul>{" "}
                        </p>

                        <div className="blog-btn">
                          {/* <a className="rn-button-style--2 btn-solid" href="#"></a> */}
                          <a
                            className="rn-button-style--2 btn-solid"
                            href="https://forms.gle/m8MTMYRJL7K5RJL28"
                          >
                            <span>Apply</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Start BLog Comment Form  */}
        {/* <div className="blog-comment-form pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <h3 className="title mb--40 fontWeight500">Leave a Reply</h3>
                  <form action="#">
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="rnform-group">
                          <input type="text" placeholder="Name" />
                        </div>
                        <div className="rnform-group">
                          <input type="email" placeholder="Email" />
                        </div>
                        <div className="rnform-group">
                          <input type="text" placeholder="Website" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="rnform-group">
                          <textarea
                            type="text"
                            placeholder="Comment"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="blog-btn">
                          <Link
                            className="rn-button-style--2 btn-solid"
                            to="/blog-details"
                          >
                            <span>SEND MESSAGE</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* End BLog Comment Form  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Hiring;
