const BlogContent = [
  {
    images: "01",
    title: "Join the Dream team at Locaro!",
    category: "Management",
  },
  // {
  //   images: "02",
  //   title: "Meet the Founding team",
  //   category: "Management",
  // },
  // {
  //   images: "03",
  //   title: "What exactly is locaro?",
  //   category: "Design",
  // },
];

export default BlogContent;
