// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

// Home layout
// import Demo from "./page-demo/Demo";
import Main from "./home/Main";
// import Startup from "./home/Startup";
// import Paralax from "./home/Paralax";
// import HomePortfolio from "./home/HomePortfolio";
// import DigitalAgency from "./home/DigitalAgency";
// import CreativeAgency from "./home/CreativeAgency";
// import PersonalPortfolio from "./home/PersonalPortfolio";
// import Business from "./home/Business";
// import StudioAgency from "./home/StudioAgency";
// import PortfolioLanding from "./home/PortfolioLanding";
// import CreativeLanding from "./home/CreativeLanding";
// import HomeParticles from "./home/HomeParticles";
// import CreativePortfolio from "./home/CreativePortfolio";
// import DesignerPortfolio from "./home/DesignerPortfolio";
// import InteriorLanding from "./home/Interior";
// import CorporateBusiness from "./home/CorporateBusiness";
// import InteractiveAgency from "./home/InteractiveAgency";

// Dark Home Layout
// import DarkMainDemo from "./dark/MainDemo";
// import DarkPortfolioLanding from "./dark/PortfolioLanding";

// Element Layout
// import Service from "./elements/Service";
// import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
// import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import Hiring from "./elements/Hiring";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";

// Blocks Layout

// import Team from "./blocks/Team";
// import Counters from "./blocks/Counters";
// import Testimonial from "./blocks/Testimonial";
// import Portfolio from "./blocks/Portfolio";
// import VideoPopup from "./blocks/VideoPopup";
// import Gallery from "./blocks/Gallery";
// import Brand from "./blocks/Brand";
// import ProgressBar from "./blocks/ProgressBar";
// import ContactForm from "./blocks/ContactForm";
// import GoogleMap from "./blocks/GoogleMap";
// import Columns from "./blocks/Columns";
// import PricingTable from "./blocks/PricingTable";

import { BrowserRouter, Switch, Route } from "react-router-dom";
// import * as serviceWorker from "./serviceWorker";

class Root extends Component {
  render() {
    return (
      <BrowserRouter>
        <PageScrollTop>
          <Switch>
            <Route exact path={`/`} component={Main} />

            {/* Element Layot */}
            {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
              component={Contact}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/about`}
              component={About}
            /> */}
            {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog`}
              component={Blog}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-details`}
              component={BlogDetails}
            /> */}

            <Route exact path={`/join`} component={Hiring} />

            <Route path={`/404`} component={error404} />
            <Route component={error404} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
